/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ea001739-6af5-48b5-80e9-d6177214edc7",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_lQPn2VgLX",
    "aws_user_pools_web_client_id": "7jr2f6rahrfv2mbpuqfogivjob",
    "oauth": {
        "domain": "outponged-auth-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        // "redirectSignIn": "http://localhost:3000/",
        // "redirectSignOut": "http://localhost:3000/",
        "redirectSignIn": "https://outponged.com/",
        "redirectSignOut": "https://outponged.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
